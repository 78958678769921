@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap');
body {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 15px;
    color: #000;
    font-weight: 300;
    background-color: #f5f5f5 !important;
    overscroll-behavior-y: contain;
}
.nav-logo {
    width: auto;
    height: 50px;
    padding: 2px;
    margin-left: 10px;
}

.nav-link{

    font-weight: 400;
    font-size:20px;
    font-family: 'Roboto Condensed', sans-serif;
}
.nav-font{
    color: red!important;

    font-weight: 400;
    font-family: 'Roboto Condensed', sans-serif;
}
.nav-font:hover{
    color: #ffffff!important;
    font-weight: 400;
    font-family: 'Roboto Condensed', sans-serif;
}
.text-danger{
    color: #fe0a52 !important;;
}
.btn-danger{
    background: #fe0a52 !important;
}
.preview-img{
    border: 1px solid #fe0a52;
    opacity:1;
    width: 90%;
    height: 260px;
    object-fit: cover;
    position: relative;
}
.btn {
    border-radius: 3px !important;
    cursor: pointer !important;
    font-weight: 300;
    font-family: 'Roboto Condensed', sans-serif;
    -webkit-tap-highlight-color: transparent;
    box-shadow: 0 0 0 0 rgba(0,0,0,.16),0 4px 10px 0 rgba(0,0,0,.12);
}

.btn:hover {
    cursor: pointer !important;
    -webkit-transition: all .2s ease-in;
    -webkit-transform: scale(1.01);
    -ms-transform: scale(1.01);
    -moz-transition: all .2s ease-in;
    -moz-transform: scale(1.01);
    transition: all .2s ease-in;
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-in;
    transition-delay: 0s;
    transform: scale(1.01);
}

.btn:focus, .btn:hover {
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.16),0 2px 2px 0 rgba(0,0,0,.12);
}


.loader-size{
    width: 100px;
    height: 100px;
}

.LoadingOverlay {
    background: rgba(0, 0, 0, 0.8);
    display: block;
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 500;
    right: 0;
    bottom: 0;
}




.canvasClass{
    z-index: 500;
    opacity:1;
    width: 90%;
    height: 300px;
    position: relative;
}

.WebcamClass{
    z-index: -500;
    opacity:0;
    width: 90%;
    height: 260px;
    position: relative;
}
.form-label{
    font-weight: 400;
    font-size: 12px;
    font-family: 'Roboto Condensed', sans-serif;
}


.form-control:focus {
    border-color:#fe0a52 !important;
    box-shadow: none !important;
    outline: none !important;
}
.form-control{
    box-shadow: none;
    font-size:13px;
    font-weight:500;
    font-family: 'Roboto', sans-serif !important;;
    border-radius: 0px !important;
}
.form-select:focus {
    border-color:#fe0a52 !important;
    box-shadow: none !important;;
}
.form-select{
    box-shadow: none;
    font-size:14px;
    font-weight:300;
    padding: 10px;
    border-radius: 0% !important;
}

.overlay{
    background: rgba(0,0,0,0.5);
    display: block;
    position: fixed;
    height: 100%;
    z-index: 500;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}
.center-screen{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}

.CircularProgressbar{
    width: 45%;
}

.PlayerCanvasClass{
    opacity: 0;
    width: 100%;
    height: 320px;
    position: relative;
}

